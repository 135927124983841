<script setup>
import {
  toRefs, computed, ref, watch, nextTick, defineProps, defineEmits,
} from 'vue';
import { isEmpty } from '../../services/Utils/Validations';
import { Validator } from '../../services/Utils/Validator';

const props = defineProps({
  label: {
    type: String,
    default: 'nombre',
  },
  className: {
    type: String,
  },
  value: {
    type: [Object, Array, String, Number],
  },
  validations: {
    type: Object,
  },
  disabled: {
    type: Boolean,
  },
  accept: {
    type: Array,
    default: () => [
      'application/vnd.ms-excel',
      'application/pdf',
      'image/png',
      'image/jpeg',
      'image/jpg',
    ],
  },
  textColor: {
    type: Array,
    default: () => [],
  },
  errors: {
    type: Array,
    default: () => [],
  },
});

const {
  accept,
  className,
  value,
  validations,
  disabled,
  errors,
} = toRefs(props);

const emit = defineEmits(['onChange', 'setError']);

const inputFile = ref(null);

const localAccept = computed(() => accept.value.join(','));

const allowedFiles = (files) => [...files].filter((f) => accept.value.includes(f.type));

const uploadFile = (evt) => {
  const el = evt.target;
  const { files } = el;
  if (files.length > 0) {
    emit('onChange', allowedFiles(files));
  }
};

const dropFiles = (evt) => {
  evt.preventDefault();
  if (evt.dataTransfer?.files.length) {
    const { files } = evt.dataTransfer;
    emit('onChange', allowedFiles(files));
  }
};

const resetEvent = () => {
  emit('onChange', []);
};

const errorMessages = ref([]);
const validate = new Validator();
const handleValidations = (val) => {
  if (validations?.value) {
    validate.validate(val, validations.value);
    if (!isEmpty(validate.getErrors)) {
      errorMessages.value = validate.getErrors;
      emit('setError', true);
    } else {
      errorMessages.value = [];
      emit('setError', false);
    }
  }
};

const checkIt = () => {
  handleValidations(value?.value);
  document.body.onfocus = null;
};

const initialize = () => {
  document.body.onfocus = checkIt;
};

const handleClick = () => {
  if (!disabled.value) {
    // eslint-disable-next-line no-unused-expressions
    inputFile.value.click();
    if (inputFile.value) {
      initialize();
    }
  }
};

watch(errors, async (newVal) => {
  errorMessages.value = [...errorMessages.value, ...newVal];
  await nextTick();
  if (errorMessages.value.length > 0) {
    emit('setError', true);
  }
});

</script>
<template>
    <div>
        <div
        :class="`
        ${className} cursor-pointer border-files flex flex-col justify-center items-center w-full
        ${isEmpty(errorMessages) ? 'color-files-normal' : 'color-files-error'}`"
        >
            <div
            @dragover.prevent
            @dragenter.prevent
            @drop="dropFiles($event)"
            @click="handleClick"
            class="border-files-box text-center"
            >
            <div class="flex justify-center">
                <img src="../../assets/nube-file.svg" />
            </div>
            <template v-if="!isEmpty(value)">
                Archivos seleccionados
            </template>
            <div v-else class="flex justify-center mt-1 cursor-pointer">
              <slot name="full" >
                <div class="text-center" style="line-height: 1.1; font-size: 14.8px">
                Soltá el archivo en este recuadro o
                <span :class="`${isEmpty(errorMessages) ? 'text-primary' : 'text-danger'}`">seleccionalo</span>
                 desde tu dispositivo
                </div>
              </slot>
            </div>
            </div>
            <input
            v-show="false"
            @click="resetEvent()"
            @change="uploadFile"
            :accept="localAccept"
            multiple
            type="file"
            ref="inputFile"
            capture
            />
        </div>
        <template v-if="!isEmpty(errorMessages) && errorMessages">
            <div class="flex">
                <span
                class="text-danger "
                v-for="(err,index) in errorMessages"
                :key="index"
                >
                    <!-- eslint-disable-next-line vue/no-parsing-error -->
                    <small>{{ errorMessages.length > 1 && index < errorMessages.length - 1 ? err + ', ' : err }}</small>
                </span>
            </div>
        </template>
    </div>
</template>

<style>
.border-files {
  padding: 8px 10px;
  border-radius: 35px;
  width: 21rem;
  height: 4rem;
}

.color-files-normal {
    border: 2px dotted #a19a9a;
}

.color-files-error {
    border: 2px dotted #e33748;
}

.border-files-box {
  width: 17rem;
  /* height:18rem;
    background:red;*/
}
</style>
